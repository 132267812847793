#datalist {
  margin: auto;
  position: relative;
  width: 100%;
  min-width: 220px;
  color: #000;
  height: 36px;
}
#datalist .input-field {
  height: 38px;
  width: 100%;
  border: 1px solid #d7dee8;
  color: #000;
  border-radius: 3px;
  padding: 0 30px 0 12px;
}
#datalist .input-field.error-border {
  border-color: #fb6f6f !important;
}
#datalist .input-field:focus {
  border-color: #d7dee8;
}
#datalist ::-webkit-scrollbar {
  height: 5px;
  width: auto;
  width: 8px;
}
@media screen and (max-width: 768px) {
  #datalist {
    min-width: 280px;
  }
}
@media screen and (max-width: 500px) {
  #datalist {
    min-width: auto;
  }
}
#datalist .form-item {
  width: 100%;
}
#datalist .arrow-img {
  width: 12px;
  height: 7px;
  position: absolute;
  right: 20px;
  top: 30%;
  color: #9aa0ac;
  transform: rotate(0deg);
  cursor: pointer;
}
#datalist.active .arrow-img {
  width: 12px;
  height: 7px;
  position: absolute;
  transform: rotate(180deg);
  right: 20px;
  top: 42%;
  cursor: pointer;
}
#datalist i {
  position: absolute;
  right: 20px;
  top: 20px;
  transition: transform 0.2s ease;
}
#datalist.active #datalist-ul {
  display: block;
}
#datalist.active #datalist-ul i {
  transform: rotate(270deg);
}
#datalist #datalist-ul {
  display: none;
  position: absolute;
  margin: 1px 0 0 0;
  padding: 0;
  width: 100%;
  max-height: 105px;
  top: 100%;
  left: 0;
  list-style: none;
  border: 1px solid #d7dee8;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 9999;
}
#datalist #datalist-ul.open-top {
  top: unset;
  bottom: 40px;
}
#datalist #datalist-ul li {
  display: block;
  text-align: left;
  padding: 8px 1rem;
  min-height: 36px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.select-label {
  font-size: 12px;
}
