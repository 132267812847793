@media screen and (max-width: 585px) {
  .ReactModal__Content {
    max-height: 650px;
    width: 80%;
  }
}

.frequency-tabs {
  display: flex;
  margin-left: auto;
}

.frequency-tabs .frequency-tab {
  font-size: 0.8125rem;
  font-weight: 600;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  color: #495057;
}

.frequency-tabs .active {
  background: #efefef;
}

.frequency-tabs .tab-left {
  border-radius: 4px 0 0 4px;
}

.frequency-tabs .tab-right {
  border-radius: 0px 4px 4px 0;
}
