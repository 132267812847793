.mobile-767 {
  width: 100%;
}

.create-button {
  width: 100%;
}

.table-modal-767 {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 80%;
}

.mobile-between {
  display: flex;
  justify-content: space-between;
}

.keyword-table-pagination .margin-center {
  padding: 0;
}

@media (max-width: 767px) {
  .mobile-767 {
    width: 100%;
  }
  .mobile-pagination-767 {
    margin-top: 20px;
  }

  .table-modal-767 {
    width: 95%;
  }

  .mobile-between {
    display: flex;
    /* flex-direction: column; */
  }
}
