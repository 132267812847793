.hexomatic-dash-icon,
.hexospark-dash-icon {
  width: 20px;
  height: 16px;
}

.verified-user {
  color: green;
}

.not-verified-user {
  color: red;
}

button:disabled {
  cursor: not-allowed;
}

.margin-center {
  padding: 0;
}

.user-table td:nth-child(3),
.user-table th:nth-child(3) {
  max-width: 250px;
}

.user-table td:nth-child(5),
.user-table th:nth-child(5) {
  max-width: 80px;
}

.user-table td:nth-child(6),
.user-table th:nth-child(6) {
  max-width: 110px;
  min-width: 110px;
}

.user-table td:nth-child(8),
.user-table th:nth-child(8) {
  max-width: 120px;
}

.user-table td:nth-child(9),
.user-table th:nth-child(9) {
  max-width: 120px;
}

.user-table td:nth-child(10),
.user-table th:nth-child(10) {
  max-width: 120px;
}

.user-table td:nth-child(15),
.user-table th:nth-child(15) {
  max-width: 120px;
}

.user-table td:nth-child(16),
.user-table th:nth-child(16) {
  max-width: 100px;
}

.user-table td:nth-child(18),
.user-table th:nth-child(18) {
  max-width: 100px;
}
.delete-email-icon,
.edit-email-icon {
  padding-left: 15px;
  cursor: pointer;
}
.delete-email-icon svg:hover path {
  fill: #8c979d;
}
.edit-email-icon svg:hover path {
  stroke: #8c979d;
}
.white-loader {
  color: white;
}
.white-loader:before,
.white-loader:after {
  left: 0;
  top: 3px;
}
.shared-emails-list {
  min-width: 300px;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 10px;
}
.shared-emails-list p {
  margin-bottom: 7px;
}
.input-button-wrapper {
  margin-bottom: 5px;
}
.input-button-wrapper .form-group {
  width: 100%;
  margin-bottom: 0;
}
.input-button-wrapper .btn {
  margin-top: 26px;
  height: 38px;
}
