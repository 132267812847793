.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}

.check-wrapper {
  display: flex;
  align-items: center;
}

.check-wrapper span {
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 0.3rem;
  margin-right: 10px;
  text-transform: capitalize;
}
