.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}

.preview-modal {
  width: 400px;
  height: 400px;
  margin: 10% auto 0 auto;
  border: 1px solid #e5e5e5;
  background: #f5f7fb;
}

.preview-modal div {
  height: 100%;
  display: flex;
  background: #f5f7fb !important;
}

.preview-modal div a {
  display: inline-block;
  margin: auto;
  background: #fff !important;
}

.preview-modal div a div {
  display:block;
  background: #fff !important;
}

.test-email-btn {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: -0.5rem;
}

.test-email-btn button {
  margin-top: -0.5rem;
}