@media (max-width: 767px) {
  .no-col-flex {
    flex: none;
  }
}
.graph-link {
  cursor: pointer;
}

.from-to-datepicker {
  max-width: 873px;
}

.between {
  justify-content: space-between;
}

.refresh-icon {
  margin-left: 30px;
}

.refresh-icon svg {
  width: 20px;
  height: 20px;
}

.pichart-title {
  display: flex;
  /* align-items: center; */
  flex-direction: row-reverse;
}
