.tableMargin {
  margin: 35% 0 0 20%;
}

.table.card-table th {
  cursor: pointer;
}

@media (max-width: 767px) {
  td,
  th > div {
    font-size: 10px;
    padding: 2px;
  }
}

.table-responsive {
  height: 100%;
  max-height: 750px;
}
