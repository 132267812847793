.chart-wrapper-width {
  margin: auto;
  max-width: 1440px;
}

@media print {
  .print-only,
  .print-only > * {
    display: block !important;
  }

  .no-print {
    display: none !important;
  }

  .card-footer {
    display: none !important;
  }

  .chart-wrapper-width {
    max-width: 1440px !important;
  }
  .c3 {
    width: 100% !important;
    /* max-width: 1440px !important; */
  }
}

.loader-small {
  width: 1rem !important;
  height: 1rem !important;
}
.loader-small:before,
.loader-small:after {
  width: 1rem !important;
  height: 1rem !important;
  margin: -0.25rem 0 !important;
}
.share-profile-error {
  padding: 3px 10px !important;
  margin-bottom: 5px !important;
}
