.container  .row div.col-lg-3 {
  display: none;
}

.nav-item {
  padding: 0 0.5rem !important;
}

.nav-tabs .nav-link {
  color: #8cc621;
  padding: 0 !important;
}

.nav-link.active {
  color: #8cc621 !important;
  border-color: #8cc621 !important;
}

.header-menu-wrapper .navs-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-menu-wrapper .selects-block {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-menu-wrapper .selects-block .select-wrapper {
  display: flex;
  align-items: center;
  width: 20%;
  position: relative;
  margin-right: 15px;
}

.header-menu-wrapper .selects-block .select-wrapper img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}


@media (max-width: 767px) {
  .no-col-flex {
    flex: none;
  }
}
.graph-link {
  cursor: pointer;
}

.from-to-datepicker {
  max-width: 873px;
}

.between {
  justify-content: space-between;
}

.refresh-icon {
  margin-left: 30px;
}

.refresh-icon svg {
  width: 20px;
  height: 20px;
}

.pichart-title {
  display: flex;
  /* align-items: center; */
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .no-col-flex {
    flex: none;
  }
}
.graph-link {
  cursor: pointer;
}

.from-to-datepicker {
  max-width: 873px;
}

.between {
  justify-content: space-between;
}

.refresh-icon {
  margin-left: 30px;
}

.refresh-icon svg {
  width: 20px;
  height: 20px;
}

.pichart-title {
  display: flex;
  /* align-items: center; */
  flex-direction: row-reverse;
}

.chart-wrapper-width {
  margin: auto;
  max-width: 1440px;
}

@media print {
  .print-only,
  .print-only > * {
    display: block !important;
  }

  .no-print {
    display: none !important;
  }

  .card-footer {
    display: none !important;
  }

  .chart-wrapper-width {
    max-width: 1440px !important;
  }
  .c3 {
    width: 100% !important;
    /* max-width: 1440px !important; */
  }
}

.loader-small {
  width: 1rem !important;
  height: 1rem !important;
}
.loader-small:before,
.loader-small:after {
  width: 1rem !important;
  height: 1rem !important;
  margin: -0.25rem 0 !important;
}
.share-profile-error {
  padding: 3px 10px !important;
  margin-bottom: 5px !important;
}

@media(max-width: 767px) {
  .offset-9 {
    margin-left: 0 !important;
  }
} 
button {
  border: none;
  padding: 8px 17px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

button:focus {
  box-shadow: none !important;
}

.margin-center {
  text-align: center
}

.tableMargin {
  margin: 35% 0 0 20%;
}

.table.card-table th {
  cursor: pointer;
}

@media (max-width: 767px) {
  td,
  th > div {
    font-size: 10px;
    padding: 2px;
  }
}

.table-responsive {
  height: 100%;
  max-height: 750px;
}

.mobile-767 {
  width: 100%;
}

.create-button {
  width: 100%;
}

.table-modal-767 {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 30%;
    height: 80%;
}

.mobile-between {
  display: flex;
  justify-content: space-between;
}

.keyword-table-pagination .margin-center {
  padding: 0;
}

@media (max-width: 767px) {
  .mobile-767 {
    width: 100%;
  }
  .mobile-pagination-767 {
    margin-top: 20px;
  }

  .table-modal-767 {
    width: 95%;
  }

  .mobile-between {
    display: flex;
    /* flex-direction: column; */
  }
}

.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.hexomatic-dash-icon,
.hexospark-dash-icon {
  width: 20px;
  height: 16px;
}

.verified-user {
  color: green;
}

.not-verified-user {
  color: red;
}

button:disabled {
  cursor: not-allowed;
}

.margin-center {
  padding: 0;
}

.user-table td:nth-child(3),
.user-table th:nth-child(3) {
  max-width: 250px;
}

.user-table td:nth-child(5),
.user-table th:nth-child(5) {
  max-width: 80px;
}

.user-table td:nth-child(6),
.user-table th:nth-child(6) {
  max-width: 110px;
  min-width: 110px;
}

.user-table td:nth-child(8),
.user-table th:nth-child(8) {
  max-width: 120px;
}

.user-table td:nth-child(9),
.user-table th:nth-child(9) {
  max-width: 120px;
}

.user-table td:nth-child(10),
.user-table th:nth-child(10) {
  max-width: 120px;
}

.user-table td:nth-child(15),
.user-table th:nth-child(15) {
  max-width: 120px;
}

.user-table td:nth-child(16),
.user-table th:nth-child(16) {
  max-width: 100px;
}

.user-table td:nth-child(18),
.user-table th:nth-child(18) {
  max-width: 100px;
}
.delete-email-icon,
.edit-email-icon {
  padding-left: 15px;
  cursor: pointer;
}
.delete-email-icon svg:hover path {
  fill: #8c979d;
}
.edit-email-icon svg:hover path {
  stroke: #8c979d;
}
.white-loader {
  color: white;
}
.white-loader:before,
.white-loader:after {
  left: 0;
  top: 3px;
}
.shared-emails-list {
  min-width: 300px;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 10px;
}
.shared-emails-list p {
  margin-bottom: 7px;
}
.input-button-wrapper {
  margin-bottom: 5px;
}
.input-button-wrapper .form-group {
  width: 100%;
  margin-bottom: 0;
}
.input-button-wrapper .btn {
  margin-top: 26px;
  height: 38px;
}

.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}
.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}
.shortcutClicksDatePicker .react-datepicker-wrapper {
  width: 100%;
}

.header-data-btn-input {
  display: flex;
  justify-content: space-between;
}

.mobile-search-input-767 {
  width: 80%;
}
.mobile-btn-767 {
  width: 20%;
}

@media(max-width: 768px) {
  .header-data-btn-input {
    /* flex-direction: column; */
  }
  .mobile-search-input-767 {
    width: 80%;
  }
  .mobile-btn-767 {
    width: 20%;
  }
}
.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.resolved-task {
  color: green;
}
.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.black-list-table-pagination {
  width: 100%;
}

.subscribed-emails .justify-content-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.justify-content-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.justify-content-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}

.field-list {
  background-color: rgba(124, 124, 124, 0.1);
  max-height: 300px;
  overflow: auto;
}

.field-list > .item {
  padding: 5px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.field-list > .item:hover {
  background-color: rgba(124, 124, 124, 0.3);
}

.selected-fields {
  background-color: rgba(124, 124, 124, 0.1);
}

.selected-fields .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.prices {
  border-left: 1px solid gray;
  padding: 0 0 0 5px;
  margin-bottom: 10px;
  background-color: rgba(124, 124, 124, 0.1);
}

.prices p {
  white-space: nowrap;
  margin: 0;
  line-height: 16px;
}

.crs-point {
  cursor: pointer;
}
.long-description {
  font-weight: 600;
  font-size: 0.875rem;
}

.settings-title {
  border-top: 1px dashed #c1c1c1;
  color: #000;
  font-size: 16px;
  padding: 10px 0 0;
}

.settings-title span {
  font-weight: 700;
}

.table-modal thead {
  background: rgba(135, 150, 165, 0.064);
  color: #adb5bd;
  font-size: 0.75rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.table-modal tbody td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.table-modal .sm {
  width: 10%;
}

.table-modal .md {
  width: 30%;
}

.table-modal .lg {
  width: 60%;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-150 {
  max-width: 150px;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.add-output-key-container {
  display: flex;
  margin-top: 20px;
}

.add-output-key-container .btn-container button {
  height: 38px;
  margin-top: 28px;
}

.add-output-key-container .sm {
  width: 10%;
  padding: 0 5px;
}

.add-output-key-container .md {
  width: 30%;
  padding: 0 5px;
}

.add-output-key-container .lg {
  width: 60%;
  padding: 0 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.multi-select-disabled .select-item.false {
  pointer-events: none;
}

.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}

.preview-modal {
  width: 400px;
  height: 400px;
  margin: 10% auto 0 auto;
  border: 1px solid #e5e5e5;
  background: #f5f7fb;
}

.preview-modal div {
  height: 100%;
  display: flex;
  background: #f5f7fb !important;
}

.preview-modal div a {
  display: inline-block;
  margin: auto;
  background: #fff !important;
}

.preview-modal div a div {
  display:block;
  background: #fff !important;
}

.test-email-btn {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: -0.5rem;
}

.test-email-btn button {
  margin-top: -0.5rem;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}

.check-wrapper {
  display: flex;
  align-items: center;
}

.check-wrapper span {
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 0.3rem;
  margin-right: 10px;
  text-transform: capitalize;
}

.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}

.react-datepicker__input-container input,
.custom-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-typeography {
  font-weight: 900;
}

#datalist {
  margin: auto;
  position: relative;
  width: 100%;
  min-width: 220px;
  color: #000;
  height: 36px;
}
#datalist .input-field {
  height: 38px;
  width: 100%;
  border: 1px solid #d7dee8;
  color: #000;
  border-radius: 3px;
  padding: 0 30px 0 12px;
}
#datalist .input-field.error-border {
  border-color: #fb6f6f !important;
}
#datalist .input-field:focus {
  border-color: #d7dee8;
}
#datalist ::-webkit-scrollbar {
  height: 5px;
  width: auto;
  width: 8px;
}
@media screen and (max-width: 768px) {
  #datalist {
    min-width: 280px;
  }
}
@media screen and (max-width: 500px) {
  #datalist {
    min-width: auto;
  }
}
#datalist .form-item {
  width: 100%;
}
#datalist .arrow-img {
  width: 12px;
  height: 7px;
  position: absolute;
  right: 20px;
  top: 30%;
  color: #9aa0ac;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  cursor: pointer;
}
#datalist.active .arrow-img {
  width: 12px;
  height: 7px;
  position: absolute;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  right: 20px;
  top: 42%;
  cursor: pointer;
}
#datalist i {
  position: absolute;
  right: 20px;
  top: 20px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
#datalist.active #datalist-ul {
  display: block;
}
#datalist.active #datalist-ul i {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
#datalist #datalist-ul {
  display: none;
  position: absolute;
  margin: 1px 0 0 0;
  padding: 0;
  width: 100%;
  max-height: 105px;
  top: 100%;
  left: 0;
  list-style: none;
  border: 1px solid #d7dee8;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 9999;
}
#datalist #datalist-ul.open-top {
  top: unset;
  bottom: 40px;
}
#datalist #datalist-ul li {
  display: block;
  text-align: left;
  padding: 8px 1rem;
  min-height: 36px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.select-label {
  font-size: 12px;
}

.mr-2 {
  margin-right: 10px;
}

.usage-filter-btn {
  max-height: 38px;
  margin-top: 26px;
}

.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}

.react-datepicker__input-container input,
.custom-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-typeography {
  font-weight: 900;
}

.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}

.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
button {
  border: none;
  padding: 8px 17px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

button:focus {
  box-shadow: none !important;
}

.margin-center {
  text-align: center
}

.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}

.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}

.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}
.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}
.shortcutClicksDatePicker .react-datepicker-wrapper {
  width: 100%;
}

.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}
.shortcutClicksDatePicker .react-datepicker-wrapper {
  width: 100%;
}

.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}
.shortcutClicksDatePicker .react-datepicker-wrapper {
  width: 100%;
}

.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}
.shortcutClicksDatePicker .react-datepicker-wrapper {
  width: 100%;
}

.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}
.shortcutClicksDatePicker .react-datepicker-wrapper {
  width: 100%;
}
.tool-source {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}

.hotjar-csv-page {
  text-align: center;
  margin: 30px 0;
}
.hotjar-csv-page .upload-file {
  color: #fff;
  background-color: #467fcf;
  border-color: #467fcf;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: 1rem;
  border-radius: 3px;
  width: 150px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 10px;
}
.hotjar-csv-page .upload-file .loader {
  height: 2.5rem;
  width: 2.5rem;
  color: white;
}

@media screen and (max-width: 585px) {
  .ReactModal__Content {
    max-height: 650px;
    width: 80%;
  }
}

.frequency-tabs {
  display: flex;
  margin-left: auto;
}

.frequency-tabs .frequency-tab {
  font-size: 0.8125rem;
  font-weight: 600;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  color: #495057;
}

.frequency-tabs .active {
  background: #efefef;
}

.frequency-tabs .tab-left {
  border-radius: 4px 0 0 4px;
}

.frequency-tabs .tab-right {
  border-radius: 0px 4px 4px 0;
}

