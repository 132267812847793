.margin-bottom {
  margin-bottom: 5%;
}

.float-left {
  float: left;
}

.margin-left {
  margin-left: 40%;
}
.shortcutClicksDatePicker .react-datepicker-wrapper {
  width: 100%;
}
.tool-source {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}
