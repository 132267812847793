.container  .row div.col-lg-3 {
  display: none;
}

.nav-item {
  padding: 0 0.5rem !important;
}

.nav-tabs .nav-link {
  color: #8cc621;
  padding: 0 !important;
}

.nav-link.active {
  color: #8cc621 !important;
  border-color: #8cc621 !important;
}

.header-menu-wrapper .navs-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-menu-wrapper .selects-block {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-menu-wrapper .selects-block .select-wrapper {
  display: flex;
  align-items: center;
  width: 20%;
  position: relative;
  margin-right: 15px;
}

.header-menu-wrapper .selects-block .select-wrapper img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

