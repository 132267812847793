.header-data-btn-input {
  display: flex;
  justify-content: space-between;
}

.mobile-search-input-767 {
  width: 80%;
}
.mobile-btn-767 {
  width: 20%;
}

@media(max-width: 768px) {
  .header-data-btn-input {
    /* flex-direction: column; */
  }
  .mobile-search-input-767 {
    width: 80%;
  }
  .mobile-btn-767 {
    width: 20%;
  }
}