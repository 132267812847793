.hotjar-csv-page {
  text-align: center;
  margin: 30px 0;
}
.hotjar-csv-page .upload-file {
  color: #fff;
  background-color: #467fcf;
  border-color: #467fcf;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: 1rem;
  border-radius: 3px;
  width: 150px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 10px;
}
.hotjar-csv-page .upload-file .loader {
  height: 2.5rem;
  width: 2.5rem;
  color: white;
}
