.black-list-table-pagination {
  width: 100%;
}

.justify-content-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}