.black-list-table-pagination {
  width: 100%;
}

.black-list-table-pagination .margin-center {
  padding: 0;
}

.field-list {
  background-color: rgba(124, 124, 124, 0.1);
  max-height: 300px;
  overflow: auto;
}

.field-list > .item {
  padding: 5px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.field-list > .item:hover {
  background-color: rgba(124, 124, 124, 0.3);
}

.selected-fields {
  background-color: rgba(124, 124, 124, 0.1);
}

.selected-fields .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.prices {
  border-left: 1px solid gray;
  padding: 0 0 0 5px;
  margin-bottom: 10px;
  background-color: rgba(124, 124, 124, 0.1);
}

.prices p {
  white-space: nowrap;
  margin: 0;
  line-height: 16px;
}

.crs-point {
  cursor: pointer;
}
.long-description {
  font-weight: 600;
  font-size: 0.875rem;
}

.settings-title {
  border-top: 1px dashed #c1c1c1;
  color: #000;
  font-size: 16px;
  padding: 10px 0 0;
}

.settings-title span {
  font-weight: 700;
}

.table-modal thead {
  background: rgba(135, 150, 165, 0.064);
  color: #adb5bd;
  font-size: 0.75rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.table-modal tbody td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.table-modal .sm {
  width: 10%;
}

.table-modal .md {
  width: 30%;
}

.table-modal .lg {
  width: 60%;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-150 {
  max-width: 150px;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.add-output-key-container {
  display: flex;
  margin-top: 20px;
}

.add-output-key-container .btn-container button {
  height: 38px;
  margin-top: 28px;
}

.add-output-key-container .sm {
  width: 10%;
  padding: 0 5px;
}

.add-output-key-container .md {
  width: 30%;
  padding: 0 5px;
}

.add-output-key-container .lg {
  width: 60%;
  padding: 0 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.multi-select-disabled .select-item.false {
  pointer-events: none;
}
